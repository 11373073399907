<template>
    <v-container
            id="user-profile"
            fluid
            tag="section"
    >
        <v-row justify="center">
            <v-col
                    cols="12"
                    md="12"
            >
                <base-material-card>
                    <template v-slot:heading>
                        <div class="display-2 font-weight-light">
                            Laporan Laba / Rugi
                        </div>

                        <div class="subtitle-1 font-weight-light">
                            Filter
                        </div>
                    </template>

                    <v-form ref="form">
                        <v-container class="py-0">
                            <v-row>
                                <v-col
                                        cols="12"
                                        md="3"
                                >
                                    <v-menu
                                            v-model="menuTgl"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                    v-model="dateFormatted"
                                                    class="purple-input"
                                                    label="Tanggal Awal"
                                                    readonly
                                                    v-on="on"
                                            >
                                                <v-icon slot="prepend" color="green" size="30">mdi-calendar
                                                </v-icon>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker v-model="date" @input="menuTgl = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col
                                        cols="12"
                                        md="3"
                                >
                                    <v-menu
                                            v-model="menuTgl2"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                    v-model="dateFormatted2"
                                                    class="purple-input"
                                                    label="Tanggal Akhir"
                                                    readonly
                                                    v-on="on"
                                            >
                                                <v-icon slot="prepend" color="green" size="30">mdi-calendar
                                                </v-icon>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker v-model="date2" @input="menuTgl2 = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col
                                        cols="12"
                                        md="2"
                                        class="text-center"
                                        style="padding-top: 2%"
                                >
                                    <v-btn
                                            id="generateBtn"
                                            color="success"
                                            class="mr-0"
                                            @click="generate"
                                    >
                                        Tampilkan
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </base-material-card>
                <v-card>
                    <v-card-title>Laporan Laba / Rugi</v-card-title>
                    <v-card-text><h4>Periode {{dateFormatted}} {{dateFormatted ? "-" : ""}} {{dateFormatted2}}</h4></v-card-text>
                    <v-card-text>
                        <v-row>
                            <v-col
                                    cols="12"
                                    md="2">
                                <h4>Penjualan</h4>
                            </v-col>
                            <v-col
                                    cols="12"
                                    md="12">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <thead style="background-color: #4caf50;">
                                        <tr>
                                            <th class="text-center" width="12%" style="font-weight: bold;font-size: 15px;color:white">
                                                Tanggal
                                            </th>
                                            <th class="text-center" style="font-weight: bold;font-size: 15px;color:white">
                                                Item
                                            </th>
                                            <th class="text-center" style="font-weight: bold;font-size: 15px;color:white">
                                                Harga
                                            </th>
                                            <th class="text-center" width="10%" style="font-weight: bold;font-size: 15px;color:white">
                                                Qty
                                            </th>
                                            <th class="text-center" style="font-weight: bold;font-size: 15px;color:white">
                                                Total
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr
                                                v-for="(item, index) in dataPenjualan"
                                                :key="index"
                                        >
                                            <td>{{ item.tgl }}</td>
                                            <td>{{ item.item }}</td>
                                            <td class="text-right">
                                                <input type="hidden" v-model.lazy="item.harga" v-money="money" />
                                                {{ item.harga }}
                                            </td>
                                            <td class="text-right">
                                                <input type="hidden" v-model.lazy="item.qty" v-money="justnumber" />
                                                {{ item.qty }}
                                            </td>
                                            <td class="text-right">
                                                <input type="hidden" v-model.lazy="item.total" v-money="money" />
                                                {{ item.total }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" class="text-right" style="font-weight: bold">Total</td>
                                            <td class="text-right" style="font-weight: bold">
                                                <input type="hidden" v-model.lazy="totalQty_penjualan" v-money="justnumber" />
                                                {{ totalQty_penjualan }}
                                            </td>
                                            <td class="text-right" style="font-weight: bold">
                                                <input type="hidden" v-model.lazy="total_penjualan" v-money="money" />
                                                {{ total_penjualan }}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                    cols="12"
                                    md="2">
                                <h4>Pembelian</h4>
                            </v-col>
                            <v-col
                                    cols="12"
                                    md="12">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <thead style="background-color: #4caf50;">
                                        <tr>
                                            <th class="text-center" width="12%" style="font-weight: bold;font-size: 15px;color:white">
                                                Tanggal
                                            </th>
                                            <th class="text-center" style="font-weight: bold;font-size: 15px;color:white">
                                                Item
                                            </th>
                                            <th class="text-center" style="font-weight: bold;font-size: 15px;color:white">
                                                Harga
                                            </th>
                                            <th class="text-center" width="10%" style="font-weight: bold;font-size: 15px;color:white">
                                                Qty
                                            </th>
                                            <th class="text-center" style="font-weight: bold;font-size: 15px;color:white">
                                                Total
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr
                                                v-for="(item, index) in dataPembelian"
                                                :key="index"
                                        >
                                            <td>{{ item.tgl }}</td>
                                            <td>{{ item.item }}</td>
                                            <td class="text-right">
                                                <input type="hidden" v-model.lazy="item.harga" v-money="money" />
                                                {{ item.harga }}
                                            </td>
                                            <td class="text-right">
                                                <input type="hidden" v-model.lazy="item.qty" v-money="justnumber" />
                                                {{ item.qty }}
                                            </td>
                                            <td class="text-right">
                                                <input type="hidden" v-model.lazy="item.total" v-money="money" />
                                                {{ item.total }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" class="text-right" style="font-weight: bold">Total</td>
                                            <td class="text-right" style="font-weight: bold">
                                                <input type="hidden" v-model.lazy="totalQty_pembelian" v-money="justnumber" />
                                                {{ totalQty_pembelian }}
                                            </td>
                                            <td class="text-right" style="font-weight: bold">
                                                <input type="hidden" v-model.lazy="total_pembelian" v-money="money" />
                                                {{ total_pembelian }}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                            <v-col
                                    col="12"
                                    md="12">
                                <v-divider
                                   class="mx-2"></v-divider>
                            </v-col>
                            <v-col
                                col="12"
                                md="9">
                                <h4>Laba / Rugi</h4>
                            </v-col>

                            <v-col
                                    col="12"
                                    md="3"
                            class="text-right"
                            style="padding-right:20px">
                                <input type="hidden" v-model.lazy="labarugi" v-money="money" />
                                <h4>{{ labarugi }}</h4>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

                <v-dialog v-model="dialog" persistent max-width="290">
                    <v-card>
                        <v-card-text class="text-center">
                            Loading
                            <v-spacer></v-spacer>
                            <v-progress-linear
                                    indeterminate
                                    rounded
                                    color="yellow darken-2"
                            ></v-progress-linear>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-snackbar
                        v-model="snackbar"
                        :color="color"
                        :top="true"
                >
                    <span>{{message}}</span>
                    <v-btn
                            dark
                            icon
                            @click="snackbar = false">

                        <v-icon>
                            $vuetify.icons.cancel
                        </v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {ApiUrl} from '../../../../config/constant'
    import axios from 'axios'
    import {
        mapState,
    } from 'vuex'
    import {VMoney} from 'v-money'

    export default {
        name: 'LaporanLabaRugi',
        data() {
            return {
                money: {
                    decimal: ',',
                    thousands: '.',
                    prefix: 'Rp ',
                    suffix: '',
                    precision: 0,
                    masked: false /* doesn't work with directive */
                },
                justnumber: {
                    decimal: ',',
                    thousands: '.',
                    prefix: '',
                    suffix: '',
                    precision: 0,
                    masked: false /* doesn't work with directive */
                },
                snackbar: false,
                dialog: false,
                message: '',
                color: 'success',
                date: new Date().toISOString().substr(0, 10),
                dateFormatted: '',
                menuTgl: false,
                date2: new Date().toISOString().substr(0, 10),
                dateFormatted2: '',
                menuTgl2: false,
                total_penjualan: 0,
                totalQty_penjualan: 0,
                total_pembelian: 0,
                totalQty_pembelian: 0,
                labarugi: 0,
                dataPenjualan: [
                    {
                        id: 1,
                        tgl: '11-01-2021',
                        item: 'Capuccino',
                        qty: 2,
                        harga: 10000,
                        total: 20000,
                    },
                ],
                dataPembelian: [
//                    {
//                        id: 1,
//                        tgl: '11-01-2021',
//                        item: 'Capuccino',
//                        qty: 2,
//                        harga: 10000,
//                        total: 20000,
//                    },
//                    {
//                        id: 2,
//                        tgl: '13-01-2021',
//                        item: 'Americano',
//                        qty: 1,
//                        harga: 8000,
//                        total: 8000,
//                    },
                ],

            }
        },
        computed: {
            ...mapState(['currentUser']),
            computedDateFormatted() {
                return this.formatDate(this.date)
            },
            computedDateFormatted2() {
                return this.formatDate2(this.date2)
            },
        },
        watch: {
            date: function (val) {
                this.formatDate(val)
            },
            date2: function (val) {
                this.formatDate2(val)
            }
        },
        mounted: function () {
            this.computedDateFormatted
            this.computedDateFormatted2
        },
        methods: {
            formatDate: function (date) {
                if (!date) return null
                const [year, month, day] = date.split('-')
                if (year.length > 3)
                    this.dateFormatted = `${day}-${month}-${year}`
                else
                    this.dateFormatted = this.date
            },
            formatDate2: function (date) {
                if (!date) return null
                const [year, month, day] = date.split('-')
                if (year.length > 3)
                    this.dateFormatted2 = `${day}-${month}-${year}`
                else
                    this.dateFormatted2 = this.date2
            },

            generate: function () {
                if (this.date != '' && this.date2 != '') {
                    let token = this.currentUser.token;
                    let header = {
                        'Authorization': 'Bearer ' + token,
                        'Content-type': 'application/json'
                    }
                    let data = {
                        tglawal: this.date,
                        tglakhir: this.date2,
                    }
                    this.dialog = true
                    axios
                    ({method: 'post', url: ApiUrl + 'laporan/labarugi', data: data, headers: header})
                        .then(response => {
                            console.log(response)
                            this.dialog = false
                            if (response.data.success) {
                                this.snackbar = true
                                this.color = 'success'
                                this.message = 'Berhasil generate laporan                    '
                                this.dataPenjualan = response.data.dataJmlPenjualan
                                this.dataPembelian = response.data.dataJmlPengadaan
                                this.totalQty_penjualan = response.data.dataTotPenjualan[0].tot_qty
                                this.totalQty_pembelian = response.data.dataTotPengadaan[0].tot_qty
                                this.total_penjualan = response.data.dataTotPenjualan[0].tot_price
                                this.total_pembelian = response.data.dataTotPengadaan[0].tot_price
                                this.labarugi = response.data.labarugi
//                                this.$refs.form.resetValidation()
                            } else {
                                this.snackbar = true
                                this.color = 'error'
                                this.message = 'Gagal generate laporan                    '
                            }
                        })
                        .catch(error => {
                            this.dialog = false
                            this.snackbar = true
                            this.color = 'error'
                            this.message = 'Gagal simpan \n '+error.message
                            console.log(error)
                        })
                } else {
                    this.snackbar = true
                    this.color = 'error'
                    this.message = 'Tanggal awal dan akhir harus diisi'
                }
            },
        }
    }
</script>
